import React, { useState, useEffect, useRef } from 'react';
import DataTable from '../DataTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllFiles, getSearchResults, syncDocuments } from '../../../Redux/Actions/action.document';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from './../../firebaseConfig';
import { logout, setLoadingValue } from '../../../Redux/Actions/action.users';

function Documents({ documents, getAllFiles, pageInfo, getSearchResults, docsToSync, syncDocuments, toast, closeToast, logout, setLoadingValue, customerKey, categoriesOptions }) {
  const navigate = useNavigate();
  const [perPageEntries, setPerPageEntries] = useState(100);
  const [allSelectedCategories, setAllSelectedCategories] = useState(categoriesOptions.map(category => category.id));
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    perPageSize: perPageEntries,
    sortColumn: 'ingestionDatetime',
    sortType: 'desc',
    searchString: '',
    // customerKey: customerKey,
    categoryIds: allSelectedCategories
  });
  const [searchTerm, setSearchTerm] = useState('');
  // const [searchObject,setSearchObject] = useState({});
  const [pages, setPages] = useState({
    numberOfPages: [...Array(1)],
    pagesDisplayFrom: 1,
    pagesDisplayTo: 1
  });



  const searchRef = useRef(null);


  const [categoryOptionsArray, setCategoryOptionsArray] = useState(categoriesOptions);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const [fileCategoriesSelected,setFileCategoriesSelected] = useState([]);
  const [searchCategoriesInput, setSearchCategoriesInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const categoriesSelectRef = useRef(null);

  useEffect(() => {
    const wrapper = document.getElementById('document-ingestion-container-wrapper');
    wrapper.addEventListener('click', handleClickOutside);
    return () => {
      wrapper.removeEventListener('click', handleClickOutside);
    }
  }, [])

  const handleClickOutside = (e) => {
    if (categoriesSelectRef.current && !categoriesSelectRef.current.contains(e.target) && e.target.id != 'search-category-input' && e.target.id != 'dropdown-arrow') {
      setShowOptions(false);
      clearSearchFilter();
    }
  }

  const clearSearchFilter = () => {
    setSearchCategoriesInput('');
    setCategoryOptionsArray(categoriesOptions);
  }


  const handleDropdown = () => {
    setShowOptions(prev => !prev);
  }

  const handleCategoryOptionSelect = (category) => {
    if (selectedCategories && selectedCategories.find(el => el == category)) return;
    const selectCategories = [...selectedCategories, category];
    setSelectedCategories(selectCategories);
    // setFileCategoriesSelected(prev=>([...prev,category.id]));
    setPageDetails(prev => ({
      ...prev,
      categoryIds: selectCategories.map(category => category.id)
    }))
  }

  const handleSearchCategory = (searchterm) => {
    setCategoryOptionsArray(categoriesOptions.filter(el => el.name.toLowerCase().includes(searchterm.toLowerCase())))
  }

  const removeSelectedCategory = (categoryId) => {

    const selectedCategory = selectedCategories.filter(category => category.id !== categoryId)
    setSelectedCategories(selectedCategory);
    // setFileCategoriesSelected(prev=>([...prev.filter(el=>el!==categoryId)]));

    setPageDetails(prev => ({
      ...prev,
      categoryIds: selectedCategory && selectedCategory.length > 0 ? selectedCategory.map(category => category.id) : allSelectedCategories
    }))
  }

  useEffect(() => {
    const params = {
      page: pageDetails.page,
      perPageSize: pageDetails.perPageSize,
      sortColumn: 'ingestionDatetime',
      sortType: Object.keys(pageInfo).length ? pageInfo.sortType : 'desc',
      searchString: pageDetails.searchString,
      categoryIds: pageDetails.categoryIds,
    }
    getAllFiles(params).then(res => {
      if (res && res.status == 'logout') {
        signOut(auth);
        navigate('/');
        console.log('Error', 'Session Expired.')
      }
    });
  }, [pageDetails]);


  useEffect(() => {
    if (pageInfo) {
      const startPage = pageInfo.currentPage % 5 == 0 ? pages.pagesDisplayFrom : Math.floor((pageInfo.currentPage) / 5) * 5 + 1;
      const endPage = pageInfo.totalPages > 5 ? pageInfo.currentPage % 5 == 0 ? pages.pagesDisplayTo : (Math.floor((pageInfo.currentPage) / 5) * 5 + 5 > pageInfo.totalPages ? pageInfo.totalPages : Math.floor((pageInfo.currentPage) / 5) * 5 + 5) : pageInfo.totalPages
      setPages(prev => ({
        ...prev,
        numberOfPages: [...Array(pageInfo.totalPages)],
        pagesDisplayFrom: startPage,
        pagesDisplayTo: endPage
      }))
    }
  }, [pageInfo]);

  const syncDocs = () => {
    if (docsToSync.length == 0) {
      toast('error', 'Please select a file to sync')
      return;
    }

    syncDocuments(docsToSync,customerKey).then(res => {
      if (res.status == 'logout') {
        signOut(auth)
        navigate('/');
        // toast('error','Session Expired.')
        return;
      }
      toast(res.status, res.message);
      getAllFiles(pageDetails).then(res => {
        if (res && res.status == 'logout') {
          signOut(auth);
          navigate('/');
          // toast('error','Session Expired.')
        }
      });
    }).catch(error => {
      console.log('Error:', error);
    });
  }

  const handleChange = (searchTermValue) => {
    setSearchTerm(searchTermValue);
    const pageDetailsValues = {
      ...pageDetails,
      page: 1,
      searchString: searchTermValue,
    }
    setPageDetails(pageDetailsValues);
  }

  const resetDocumentList = () => {
    const params = {
      page: 1,
      perPageSize: 100,
      sortColumn: 'ingestionDatetime',
      sortType: 'desc',
      searchString: '',
      categoryIds: allSelectedCategories
    }
    setPageDetails(params);
    setPerPageEntries(100);
    setSearchTerm('');
    setSelectedCategories([]);
    setShowOptions(false);
  }

  const pageChange = (page) => {
    setPageDetails((prev) => ({
      ...prev,
      page: page
    }));
  }

  const updatePageDetails = (e) => {
    const { name, value } = e.target
    if (!value || value == 0 || value > 100) return;
    setPageDetails((prev) => ({
      ...prev,
      [name]: value,
      page: 1
    }))
  }

  const updatePageEntries = (value) => {
    if (!value || value == 0 || value > 100) return;
    setPerPageEntries(value);
  }

  const navigatePage = (newPage) => {
    if(newPage == 0) return;
    if(newPage>pageInfo.totalPages) return;
    if(newPage > pageDetails.page && newPage<=pageInfo.totalPages && newPage>pages.pagesDisplayTo){
      changePagesDisplay('next');
    }
    if(newPage < pageDetails.page && newPage>1 && newPage<pages.pagesDisplayFrom){
      changePagesDisplay('prev');
    }
    setPageDetails((prev)=>({
      ...prev,
      page:newPage
    }))
  }

  const changePagesDisplay = (type) => {
    if (type == 'prev') {
      if (pages.pagesDisplayFrom == 1) return;
      setPages(prev => ({
        ...prev,
        pagesDisplayFrom: pages.pagesDisplayFrom - 5,
        pagesDisplayTo: pages.pagesDisplayFrom - 1
      }))
    }
    if (type == 'next') {
      if (pages.pagesDisplayTo == pages.numberOfPages.length) return;
      setPages(prev => ({
        ...prev,
        pagesDisplayFrom: pages.pagesDisplayFrom + 5,
        pagesDisplayTo: pages.pagesDisplayTo + 5 > pages.numberOfPages.length ? pages.numberOfPages.length : pages.pagesDisplayTo + 5
      }))
    }
  }





  return (
    <div className='document-container mx-4'>
      <div className='document-title py-2'>
        <div className='document-heading'>Sync Documents</div>
        <div className='search-button ms-auto'>
          <button className='btn btn-primary' onClick={() => syncDocs()}><i className="fa-solid fa-rotate" ></i> Sync</button>
        </div>
      </div>

      <div className='document-search'>
        <div className='search-title'>MetaData / Keywords</div>
        <div className='py-2 row w-100 mx-0'>
          <div className='col-xl-3 col-md-6 ps-0 pe-0 pe-md-3'>
            <div className='search-input-box w-100' ref={searchRef}>
              <input type='text' placeholder='Search Documents' className='form-control' id='search-documents' value={searchTerm} onChange={(e) => { handleChange(e.target.value) }} />
            </div>
          </div>
          <div className='col-xl-3 col-md-6 px-0 ps-md-3 pe-xl-3 mt-3 mt-md-0'>
            <div className='categories-base-dropdown-container w-100 mx-0'>
              <div className='categories-dropdown' id='categories'>
                <div className={`categories-dropdown-container`} ref={categoriesSelectRef}>
                  <div className='input-field'>
                    <div className='search-category-container'>
                      <input type='text' className={`search-category-name`} placeholder='Select Categrories' value={searchCategoriesInput} onFocus={() => setShowOptions(true)} onChange={(e) => { handleSearchCategory(e.target.value); setSearchCategoriesInput(e.target.value) }} />
                      <i className="fa-solid fa-chevron-down" onClick={() => { handleDropdown(); clearSearchFilter() }}></i>
                    </div>
                    <div className='selected-categories-container'>
                      {selectedCategories && selectedCategories.map(category => {
                        return <span className='selected-category py-1'>{category.name} <i className="fa-solid fa-xmark px-1 small" onClick={() => { removeSelectedCategory(category.id) }}></i></span>
                      })}
                    </div>
                  </div>
                  <div className={`options-container py-1 px-2 ${showOptions ? '' : 'd-none'}`} >
                    {categoryOptionsArray && categoryOptionsArray.length > 0 ? categoryOptionsArray.map(category => {
                      return <li className={`py-1 category-option ${selectedCategories && selectedCategories.find(el => el.id == category.id) ? 'selected' : ''}`}
                        onClick={() => { handleCategoryOptionSelect(category) }}>{category.name}</li>
                    }) :
                      <div className={``}>No Options Available</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-md-6 ps-0 ps-xl-3 mt-3 mt-xl-0'>
            <div className='document-search-buttons'>
              <button className='btn btn-primary' onClick={() => { resetDocumentList() }}>Reset</button>
            </div>
          </div>
        </div>
      </div>

      <DataTable toast={toast} />

      <div className='document-footer pt-4'>
        <div className='page-numbers-section'>
          <div className={`previous-page px-1 mx-1 ${pages.pagesDisplayFrom == 1 ? 'disabled' : ''}`} onClick={() => { changePagesDisplay('prev') }}><i className="fas fa-angle-double-left"></i></div>
          <div className={`previous px-1 ${pageDetails.page==1?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page-1)}} title='Previous Page'><i class="fas fa-angle-left"></i></div>
          <div className={`page-number ${pages.pagesDisplayFrom == 1 ? 'd-none' : 'continuance-indicator'}`} disabled>...</div>
          {pages.numberOfPages.map((page, index) => {
            return pages.pagesDisplayFrom - 1 < index + 1 && pages.pagesDisplayTo > index && <div key={index + 1} className={`page-number px-2 ${pageDetails.page == index + 1 ? 'active' : ''}`} onClick={() => pageChange(index + 1)}>{index + 1}</div>
          })}
          <div className={`page-number ${pages.pagesDisplayTo == pages.numberOfPages.length ? 'd-none' : 'continuance-indicator'}`} disabled>...</div>
          <div className={`next px-1 ${pageDetails.page==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page+1)}} title='Next Page'><i class="fas fa-angle-right"></i></div>
          <div className={`next-page px-1 mx-1 ${pages.pagesDisplayTo == pages.numberOfPages.length ? 'disabled' : ''}`} onClick={() => { changePagesDisplay('next') }}><i className="fas fa-angle-double-right"></i></div>
        </div>
        <div className='page-number-details grow-1'>{`Showing `}
          {documents?.length ? (pageDetails.page - 1) * pageDetails.perPageSize + 1 : 0}{` to `}
          {documents?.length ? (pageDetails.page * pageDetails.perPageSize) > (pageInfo?.totalRecords) ? (pageInfo?.totalRecords) : (pageDetails.page * pageDetails.perPageSize) : 0}{` of `}
          {Object.keys(pageInfo).length ? (pageInfo?.totalRecords) : 0} entries</div>
        <div className='number-of-pages'>
          Show <input type='text' className='entries-per-page px-1' value={perPageEntries} name='perPageSize' onBlur={(e) => { updatePageDetails(e) }} onChange={(e) => { updatePageEntries(e.target.value) }} />
          entries
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.documentList,
    pageInfo: state.documents.pageInfo,
    docsToSync: state.documents.docsToSync,
    categoriesOptions: state.categories.categoryOptions,
    customerKey: state.configuration.keywords
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllFiles,
    getSearchResults,
    syncDocuments,
    setLoadingValue,
    logout
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)