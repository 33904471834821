import { combineReducers } from "redux";
import { userReducer } from "./Reducers/userReducer"; 
import { loadingState } from "./Reducers/loading";
import { documents } from "./Reducers/documents";
import { toastReducer } from "./Reducers/toastReducer";
import { configurationReducer } from "./Reducers/configurationReducer";
import { categoryReducer } from "./Reducers/categoryReducer";

export const rootReducer = combineReducers({
    users: userReducer,
    loading: loadingState,
    documents: documents,
    toasts: toastReducer,
    configuration: configurationReducer,
    categories: categoryReducer
});