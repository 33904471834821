import {ADD_KEYWORDS_LIST} from './../Actions/index';

const initialState = {
    keywords:[]
}

export const configurationReducer = (state=initialState,action) => {
    switch(action.type){
        case ADD_KEYWORDS_LIST:
            return {
                ...state,
                keywords:action.payload
            };
        default: return state;
    }
}