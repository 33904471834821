import React from 'react'

function TalkToYourData() {
  return (
    <>
    <div className='document-title py-2 d-flex flex-column align-items-start mx-md-3'>
        <div className='document-heading mb-2'>Talk To Your Data</div>
    <iframe className='iframe-dev border-0' src={process.env.REACT_APP_IFRAME_SRC} />
    </div>
    </>
  )
}

export default TalkToYourData