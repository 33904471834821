import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { configure,getAllKeywords,syncKeywords } from '../../../Redux/Actions/action.configuration';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Redux/Actions/action.users';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig'
import { getAllCategoryOptions } from '../../../Redux/Actions/action.category';
import {v4 as uuidv4, validate as uuidv4Validate} from 'uuid';


function Configuration({configure, syncKeywords, toast, closeToast, getAllKeywords, keys, logout ,getAllCategoryOptions}) {
    const navigate = useNavigate();
    const [configuration,setConfiguration] = useState('');
    const [customerKey,setCustomerKey] = useState('');
    const [validationErrors,setValidationErrors] = useState({});

    useEffect(()=>{
        getKeywords();
    },[])

    useEffect(()=>{
        if(keys){
          getAllCategoryOptions(keys).then(res=>{
            if(res && res.status == 'logout'){
                signOut(auth);
                navigate('/');
            }
          }).catch(error=>console.log(error));
        }
      },[keys])

    const getKeywords = () => {
      getAllKeywords({
        page:1,
        sort:'keywords, asc',
        size:100
      }).then(res=>{
        if(res && res.status=='logout'){
            signOut(auth);
            navigate('/');
            // toast('error','Session Expired')
        }
        if(res && res.status=='error'){
            toast('error','Error fetching keywords')
        }
      }).catch(error=>{
        console.log(error)
      });
    
    }
    const handleSubmit = async(e)=>{
        e.preventDefault();
        const isValid = await checkValidation(customerKey);
        if(!isValid) return

        const res = await configure(customerKey);
        if(res && res){
            if(res && res.status == 'logout') {          
                signOut(auth);
                navigate('/');
                // toast('error','Session Expired.')
                return;
            }
            if(res && res.status=='error'){
                toast(res.status,res.message)
            }
            if(res && res.status=='success'){
                const newToastId = 123;
                toast('success',res.message,newToastId);
                syncKeywords(customerKey).then(response=>{
                    if(response.status == 'logout'){
                        signOut(auth);
                        navigate('/');
                        // toast('error','Session Expired.')
                        return;
                    }
                    if(response && response.status=='success'){
                        closeToast(newToastId);
                        setConfiguration('');
                        toast(response.status,response.message);
                        getKeywords();
                        
                    }
                    if(response && response.status=='error'){
                        closeToast(newToastId);
                        toast(response.status,response.message);
                        getKeywords();
                    }
                    setCustomerKey('');
                }).catch(error=>{
                    console.log('Error sync API Call:',error);

                });
            }
    }
        
    }


    const checkValidation = async(value) => {
        const errors={}
        if(!value){
            errors.key='Please Enter a key to sync';
                setValidationErrors(errors);
                return false
        }
        if(value){
            const multiKey = value.trim()
            if(multiKey.split(',').length>1){
                errors.key='Multiple Keys not allowed. Enter a single valid key';
                setValidationErrors(errors);
                return false
            }
            
            const validation=multiKey.split('-');
            if(validation.length != 3 || validation[0]!='uds' || validation[1].length != 18 || validation[2].length != 6){
                if(!uuidv4Validate(multiKey.replace('uds-',''))){
                    errors.key='Invalid Key'
                }
            }
        } 
        if(Object.keys(errors).length){
            console.log(errors)
            setValidationErrors(errors);
            return false
        }else{
            setValidationErrors(errors);
            return true
        }
    }

    const handleConfigurationChange = (value)=>{
        setCustomerKey(value.trim())
    }

    return (
        <div className='configuration-container d-flex flex-column'>
            <div className='w-100'>
                <div className='configuration-title'> Configuration</div>
                <div className='keywords-input-label'>Customer Key <span className='validation-error'>{validationErrors.key}</span></div>
                <div className='py-2 keywords-input-box w-100'>
                    <input type='text' placeholder='Enter Customer Key' 
                    disabled = {keys && keys.length!==0} 
                    className='form-control' value={customerKey} onChange={(e)=>{handleConfigurationChange(e.target.value)}} />
                </div>
                <button className='btn btn-primary keywords-submit-button' 
                disabled = {keys && keys.length!==0} 
                onClick={handleSubmit}>Submit</button>
            </div>
            <div className='d-flex flex-wrap gap-2 my-3'>
                <span className="badge bg-light-gray text-dark p-2">{keys}</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state)=>{
    return {
        keys: state.configuration.keywords
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        configure,
        syncKeywords,
        getAllKeywords,
        getAllCategoryOptions,
        logout
    },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(Configuration)