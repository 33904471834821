import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {auth,db} from './../../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';


function ForgotPassword({toast}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
  const collectionRef = collection(db, 'providers');


  const sendResetEmail = async() => {
        if(!email){
            toast('warning','Please enter email address to send email');
            return
        }
        const document = await getDocs(query(collectionRef, where("email", "==", email)));
        if(!document.docs.length){
            toast('warning','No user registered on this email. Please check your email or Sign up');
            return;
        }
        if(document.docs.length && document.docs[0].data().providerId=='microsoft.com'){
            toast('warning','This email is associated with Microsoft Azure login.')
            return;
        }
        sendPasswordResetEmail(auth,email).then(res=>{
            toast('success','An Email has been sent to your email address for password reset.')
        }).catch(error=>{
            console.log('Error:',error);
        });
    }

    return (
        <div className='login-form p-4'>
            <form className='forgot-password-container border-0'>
                <img src='./images/logo.png' className='my-4 ' alt='logo' />
                <h5 className='fw-semibold'>Forgot Password?</h5>
                <p>Enter your email address. We will send you an email to set your set your password</p>
                <div className='username mb-4'>
                    <label className='form-label mb-0' htmlFor='username'>Email:</label>
                    <input id='username' className='form-control' name='email' type='text' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                </div>
                <div className='sign-up-form my-4'>
                    <button type='button' className='btn btn-primary w-100 mt-2 mb-2' onClick={()=>sendResetEmail()}>Send Password Reset Email </button>
                    <Link to='/'>Back to Sign In</Link>
                </div>
            </form>
        </div>
    )
}

export default ForgotPassword