import React, { useState, useEffect } from 'react';
import data from '../data.json'
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllFiles, updateDocsToSync } from '../../Redux/Actions/action.document';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setLoadingValue } from '../../Redux/Actions/action.users';

function DataTable({ title, documents, pageInfo, getAllFiles, updateDocsToSync, toast, setLoadingValue, customerKey }) {
  const navigate = useNavigate();
  const tableHeaders = [
    { title: 'Source', additionalClasses: '' },
    { title: 'Size', additionalClasses: '' },
    { title: 'Depth', additionalClasses: '' },
    { title: 'Categories', additionalClasses: '' },
    { title: 'Meta Data', additionalClasses: '' },
    { title: 'Keywords', additionalClasses: '' },
    { title: 'Date/Time', additionalClasses: '' },
    { title: 'Sync Status', additionalClasses: 'syncStatus-header' },
  ];
  const [documentList, setDocumentList] = useState([]);
  const [docToSync,setDocsToSync] = useState([]);
  useEffect(() => {
    if (documents) {
      setDocsToSync([]);
      let updatedList = documents;
      // let updatedList = data.data;
      updatedList = updatedList.map((file) => {
        return {
          ...file,
          filesize: file?.filesize?.split(' (')[0],
          disabled:file?.syncStatus?.toLowerCase().includes('await')?false:true,
          checked: file?.syncStatus?.toLowerCase().includes('await')?false:true
        }
      });
      setDocumentList(updatedList);

      const syncedDocs = updatedList.filter(file=>file.syncStatus.toLowerCase().includes('await'));
      if(documents.length && syncedDocs.length==0){
        document.getElementById('select-all-files-checkbox').checked = true;
        document.getElementById('select-all-files-checkbox').disabled = true;
      }
    }
  }, [documents]);

  useEffect(()=>{
    updateDocsToSync(docToSync);
  },[docToSync])
  
  const selectAll = (e) => {
    const updatedList = documentList.map((file) => {
      if(file.disabled){
        return file;
      }
      return { ...file, checked: e.target.checked }
    });
    
    const fileToSync = e.target.checked ? [...updatedList.map(file=>{
      if(!file.disabled){
        return file.id
      }
    })].filter(id=>id!==undefined) : [];

    setDocumentList(updatedList);
    setDocsToSync(fileToSync);
  }

  const changeValue = (fileIndex, checked) => {
    
    const selectAllElementsCheckbox = document.getElementById('select-all-files-checkbox');
    if(checked){
      setDocsToSync(prev=>([ ...prev, documentList[fileIndex].id ]));
      if(documentList.filter(file=>file.checked==false).length - 1 == 0){
        selectAllElementsCheckbox.checked = true 
      };
    }else{
      setDocsToSync( docToSync.filter(fileId=> fileId !== documentList[fileIndex].id ))
    }

    if(!checked){
      selectAllElementsCheckbox.checked = checked;
    }

    const updatedList = documentList.map((data, index) => {
      return index == fileIndex ? { ...data, checked: checked } : data;
    });

    // updatedList.filter(file=>{})
    setDocumentList(updatedList);
  }

  const sortArray = () => {
    const params = {
      page: pageInfo.page,
      perPageSize: pageInfo.perPageSize,
      sortType: pageInfo.sortType == 'desc' ? 'asc' : 'desc',
      searchString: pageInfo.searchString,
      categoryIds: pageInfo.categoryIds
    }
    getAllFiles(params).then(res=>{
      if(res && res.status=='logout'){
        signOut(auth);
        navigate('/');
        }
    });
  }

  const handleFileUrl = async(file) => {
    const { fileName, refUrl, syncStatus } = file;
    if(syncStatus != 'synced'){
      toast('warning','Please sync this file to open.')
      return
    }
    console.log('Open File Start')
    setLoadingValue('openFile',true);
    //---------------- GET EXTENSION FROM FILENAME --------------------------
    
    const filenameArray = fileName.split('.');
    const extension = filenameArray.length?filenameArray[filenameArray.length-1]:'';

    if(extension.toLowerCase() == 'csv'){
      window.open(refUrl);
      setLoadingValue('openFile',false);
      console.log('Opened CSV File')
      return;
    }

    if(extension.toLowerCase() == 'pdf'){
      //------------- GET FILE FROM MEDIA URL --------------------------------------      
      const docRef = {
        method:'GET',
        url:refUrl,
        responseType: 'blob',
      }
      const pdfWindow = window.open();
      pdfWindow.document.title = 'Loading...'
      await axios(docRef).then(doc=>{
        if(doc.status==200){
          const file = new File([doc.data],fileName,{type:doc.data.type});
          const fileURL = URL.createObjectURL(file);
          
          //------------- OPEN THE URL ON NEW WINDOW -------------------------------
          pdfWindow.location.href = fileURL;
          setLoadingValue('openFile',false);
          console.log('Opened PDF File')

          return;
        }
      }).catch(error=>{
        pdfWindow.close();
        toast('error','Error fetching file');
        setLoadingValue('openFile',false);
        console.log('Error Opening PDF File')
      });
      return;
    }
    window.open(fileName);
    setLoadingValue('openFile',false);
    console.log('Opened Webpage File')
  }

  return (
    <div className='document-table'>
      <div className='table-section'>
        <table className='table-properties table-bordered'>
          <thead>
            <tr className='table-headers'>
              {<th className='px-2 mx-0'><input type='checkbox' id='select-all-files-checkbox' onChange={(e) => { selectAll(e) }}></input></th>}
              {tableHeaders.map((header, index) => {
                return <th className={`py-3 px-2 mx-0 ${header.additionalClasses}`} key={index}>
                  {header.title} {header.title == 'Date/Time' && <i className="fas fa-exchange-alt sort-icon" onClick={() => { sortArray() }}></i>}
                </th>
              })}
            </tr>
          </thead>
          <tbody>
            {
              documentList.map((data, index) => {
                return <tr className='table-body-data' key={index}>
                  <td className='px-2'><input type='checkbox' checked={data.checked} disabled={data.disabled} onChange={(e) => { changeValue(index, e.target.checked) }}></input></td>
                  <td className='py-2 px-2'><p className='d-inline-block mb-0 file-link' onClick={()=>{handleFileUrl(data)}}>{data.fileName}</p></td>
                  <td className='py-2 px-2 min-w-130'>{data.filesize}</td>
                  <td className='py-2 px-2 text-center'>{data.depth}</td>
                  <td className='py-2 px-2'>
                    {data.categories.map(category=>category.name).join(', ')}
                  </td>
                  <td className='py-2 px-2'>{data.metaData}</td>
                  <td className='py-2 px-2'>{data.keywords}</td>
                  <td className='py-2 px-2 min-w-150'>{moment(data.ingestionDatetime).format('MM/DD/YYYY HH:mm')} </td>
                  <td className='py-2 px-2 syncStatus-data'>{data.syncStatus}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.documentList,
    pageInfo: state.documents.pageInfo,
    customerKey: state.configuration.keywords
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllFiles,
    updateDocsToSync,
    setLoadingValue
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable)