import {DOCUMENTS_LIST, SEARCH_OPTIONS, SYNC_DOCUMENTS, SET_CATEGORIES_OPTIONS} from './../Actions'

const initialState = {
    documentList: [],
    pageInfo:{},
    searchOptions:{},
    docsToSync: [],
    categories: []
}

export const documents = (state=initialState,action)=>{
    switch(action.type){
        case DOCUMENTS_LIST:
            return {
                ...state,
                documentList:action.payload.documentList,
                pageInfo: action.payload.pageInfo
            };
        case SEARCH_OPTIONS:
            return{
                ...state,
                searchOptions:action.payload
            }
        case SYNC_DOCUMENTS:
            return{
                ...state,
                docsToSync:action.payload
            }
        case SET_CATEGORIES_OPTIONS:
            return {
                ...state,
                categories: action.payload
            }
        default: return state
    }
}