import './Loader.css';

function Loader({loading}) {
    return (
        <div className='loader-background'>
            <div className='loader-base'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Loader