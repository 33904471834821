import axios from "axios";
import { ADD_KEYWORDS_LIST, ADD_LOADING, REMOVE_LOADING,ALLOWED_WHITELISTED_DOMAINS } from "./index";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const configure = (keywords) => async(dispatch) => {
    setLoading(dispatch,'configuration api',true);
    let response;
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    await axios({
        method:'POST',
        url:`${BASE_API_URL}/customerKey/add?key=${keywords}`,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin':'*'
        },
        // crossOrigin:true
        crossDomain:true
    }).then(res=>{
        setLoading(dispatch,'configuration api',false);
        console.log(res.data.status=='')
        if(res && res.data.statusCode==201){
            return response = {
                status:'success',
                message: 'Key added successfully.Syncing the Key. May take some time...'
            }
            // const {Failed,AlreadyExists,Success} = res.data.data;
            // if(Failed.length>0 && Success.length>0 && AlreadyExists.length>0){
            //     return response = [
            //         {status:'failed',message:`Keywords ${Failed.join()} failed to configure.`},
            //         {status:'alreadyexists',message:`Keywords ${AlreadyExists.join()} already exists.`},
            //         {status:'success',message:`Keywords ${Success.join()} successfully configured. Syncing keywords to related files. May take some time...`}
            //     ]
            // }
            // if(Failed.length>0 && Success.length>0){
            //     return response = [
            //         {status:'failed',message:`Keywords ${Failed.join()} failed to configure.`},
            //         {status:'success',message:`Keywords ${Success.join()} successfully configured. Syncing keywords to related files. May take some time...`}
            //     ]
            // }
            // if(Success.length>0 && AlreadyExists.length>0){
            //     return response = [
            //         {status:'alreadyexists',message:`Keywords ${AlreadyExists.join()} already exists.`},
            //         {status:'success',message:`Keywords ${Success.join()} successfully configured. Syncing keywords to related files. May take some time...`}
            //     ]
            // }
            // if(Failed.length>0 && AlreadyExists.length>0){
            //     return response = [
            //         {status:'failed',message:`Keywords ${Failed.join()} failed to configure.`},
            //         {status:'alreadyexists',message:`Keywords ${AlreadyExists.join()} already exists. `}
            //     ]
            // }
            // if(Failed.length>0 ){
            //     return response = [
            //         {status:'failed',message:`Keywords ${Failed.join()} failed to configure.`},
            //     ]
            // }
            // if(AlreadyExists.length>0){
            //     return response = [
            //         {status:'alreadyexists',message:`Keywords ${AlreadyExists.join()} already exists.`}
            //     ]
            // }
            // if(Success.length>0){
            //     return response = [
            //         {status:'success',message:`Keywords ${Success.join()} successfully configured. Syncing keywords to related files. May take some time...`}
            //     ]
            // }

        }
    }).catch(error=>{
        setLoading(dispatch,'configuration api',false);
        if(!error){
            return response=[{
                status:'error',
                message:'Network Error',
            }]
        }
        if (!error.response) {
            // Network error occurred
            console.error('Network error:', error);
            // response={
            //     status:'internal-server-error',
            //     message:'Server is Closed'
            // }
            return;
          }
        if(error && error.response.status==401){
            return response={
                status:'logout',
                message:'You have been logged out'
            }
        }
        console.log('Config Error',error)
        if(error && error?.response?.status==400){
            return response={
                status:'error',
                message:error?.response?.data?.messages[0],
            }
        }

        return response = {
            status: 'error',
            message: 'Error occured while adding customer key'
        }

    });

    return response;
}


export const syncKeywords = (customerKey) => async(dispatch) => {
    setLoading(dispatch,'sync keywords',true);              //===========>  Set Loading False initiated from configure API

    let response;
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    await axios({
        method:'GET',
        url:`${BASE_API_URL}/sync-process/sync`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        params:{
            customerKey:customerKey
        },
        crossOrigin:true
    }).then(res=>{
        console.log(res)
        setLoading(dispatch,'sync keywords',false);              //===========>  Set Loading False initiated from configure API
        if(res.status==200){
            response = {
                status:'success',
                message:res.data
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'sync keywords',false);        //===========>  Set Loading False initiated from configure API
        if(!error){
            return response = {
                status:'error',
                message:'Network Error'
            }
        }
        if (!error.response) {
            // Network error occurred
            console.error('Network error:', error);
            return response={
                status:'internal-server-error',
                message:'Server is Closed'
            }
          }
        if(error.status==401){
            return response={
                status:'logout',
                message:'You have been logged out'
            }
        }
        return response = {
            status:'error',
            message:'Something went wrong while syncing data.'
        }
    });
    return response;
}

export const getAllKeywords = (pageDetails) => async(dispatch) => {
  const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    if(accessToken == '') return
  setLoading(dispatch,'get_keywords_api',true);
  let response;
  const config = {
    method:'get',
    url:`${BASE_API_URL}/customerKey/fetch`,
    headers:{
        'Authorization': `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin':'*'
    },
    params:pageDetails
  }

  await axios(config).then(res=>{
    setLoading(dispatch,'get_keywords_api',false);
    if(res.data.statusCode == 200){
        response={
            status:'success',
            message:''
        }
        dispatch({
            type: ADD_KEYWORDS_LIST,
            payload: res.data.data
        })

    }
  }).catch(error=>{
    setLoading(dispatch,'get_keywords_api',false); 
    if(!error){
        return response={
            status:'error',
            message:'Network Error'
        }
    }
    if(error.response.status==401){
        return response={
            status:'logout',
            message:'Session Expired'
        }
    }
    if(error.response.status==404){
        return response = {
            status:'Not Found',
            message:''
        }
    }
    response={
      status:'error',
      message:error
    }
  });
  return response;
}

const setLoading = (dispatch, apiName, loadingState) => {

    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
}