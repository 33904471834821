import axios from "axios";
import { DOCUMENTS_LIST, SEARCH_OPTIONS, SYNC_DOCUMENTS, ADD_LOADING, REMOVE_LOADING, SET_CATEGORIES_OPTIONS,ALLOWED_WHITELISTED_DOMAINS } from './index'
import { type } from "@testing-library/user-event/dist/type";
import { setLoadingValue } from './action.users';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
let controller;

export const getAllFiles = (pageDetails) => async (dispatch) => {
    setLoading(dispatch, 'getAllFiles', true);
    let response;
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    const url = `${BASE_API_URL}/files`;

    if(controller){
        controller.abort();
    }
    controller = new AbortController();

    const params = {
        page: pageDetails.page,
        size: pageDetails.perPageSize,
        sort: `ingestionDatetime, ${pageDetails.sortType}`,
        searchString: pageDetails.searchString,
        categoryIds: pageDetails.categoryIds.join()
    }

    await axios({
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin':'*',
            // 'Access-Control-Allow-Methods':'Vary', 
            // 'Access-Control-Allow-Headers':'Vary'
        },
        signal: controller.signal,
        params: params
    }).then(async(res) => {
        setLoading(dispatch, 'getAllFiles', false);
        const [sortColumn,sortType] = res.data.page?res.data.page.sort.substring( res.data.page.sort.indexOf("[") + 1, res.data.page.sort.lastIndexOf("]")).split(', '):['desc','ingestionDatetime'];
        const data = {
            documentList: res.data.data,
            pageInfo:{...res.data.page,page:res.data.page?res.data.page.currentPage:1,sortType:sortType,sortColumn:sortColumn,searchString:pageDetails.searchString,categoryIds:pageDetails.categoryIds}
        }
        dispatch({
            type: DOCUMENTS_LIST,
            payload: data
        })

        response = {
            status: 'success',
            message: 'Files Loaded'
        }
    }).catch(error => {
        setLoading(dispatch, 'getAllFiles', false);
        if (!error) {
            // Network error occurred
            console.error('Network error:', error);
            return response={
                status:'internal-server-error',
                message:'Server is Closed'
            }
          }
        if(error && error?.response?.status==401){
        return response={
                status:'logout',
                message:'You have been logged out'
            }
        }
        if(error){
            response = {
                status: 'error',
                message: 'Server not responding currently. Please contact admin'
            }
        }
    });
    return response;
}

export const getCategories = (data) => async (dispatch) => {
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    let response;

    const config = {
        method: '',
        url: `${BASE_API_URL}/db/category/fetchAll`,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods':'Vary', 
            'Access-Control-Allow-Headers':'Vary'
        },
    }
    
    await axios(config).then(res=>{
        console.log(res)
        dispatch({
            type:SET_CATEGORIES_OPTIONS,
            payload:data
        })
    }).catch(error=>{
        if(!error){
            return response = {
                status:'error',
                message:' Network Error'
            }
        }
    })
   
}

export const getSearchResults = (searchTerm) => async (dispatch) => {
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    let response;
    const url = `${BASE_API_URL}/searchFiles`;
    const params = {
        searchString: searchTerm
    }
    response = axios({
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin':'*'
        },
        params: params
    });

    return response;
}

export const syncDocuments = (docsToSync,customerKey) => async (dispatch) => {
    let response;
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    const url = `${BASE_API_URL}/sync/manual`;
    const parsedUrl = new URL(url);
    const body = docsToSync;
    setLoading(dispatch, 'syncDocuments', true);
    if(ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)){
        await axios({
            method: 'POST',
            url: url,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Methods':'Vary', 
                'Access-Control-Allow-Headers':'Vary'
            },
            params:{
                customerKey:customerKey
            },
            data: body
        }).then(res=>{
            setLoading(dispatch, 'syncDocuments', false);
            if(res.status == 200){
                response={
                    status:'success',
                    message:'Files Synced.'
                }
            }
        }).catch(error=>{
            setLoading(dispatch, 'syncDocuments', false);
            if (!error.response) {
                // Network error occurred
                console.error('Network error:', error);
                return response={
                    status:'internal-server-error',
                    message:'Server is Closed.'
                }
              }
            if(error.response.status==401){
                return response={
                    status:'logout',
                    message:'You have been logged out'
                }
            }
            if(error.response.status==400){
                console.log(error.response)
                return response = {
                    status:'error',
                    message:error?.response?.data?.data
                }
            }
            response={
                status:'error',
                message:'Error Syncing Documents'
            }
        });

    }else{
        response = {
            status:'error',
            message:'Domain not allowed'
        }
    }
    setLoading(dispatch, 'syncDocuments', false);
    return response;
}


export const updateDocsToSync = (docId) => (dispatch) => {
    dispatch({
        type: SYNC_DOCUMENTS,
        payload: docId
    })
}


const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
}