import axios from "axios";
import { SET_CATEGORY_OPTIONS,ADD_LOADING,REMOVE_LOADING } from "./index";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllCategoryOptions = (customerKey) => async(dispatch) => {
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    if(accessToken =='') return;
    if(!customerKey.length) return;
    setLoading(dispatch,'fetch-categories',true);
    let response;
    const config = {
        method:'GET',
        url:`${BASE_API_URL}/category/fetchAllCategoriesForDropdown?customerKey=${customerKey}`,
        headers:{
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin':'*'
        }
    }
    await axios(config).then(res=>{
    setLoading(dispatch,'fetch-categories',false);
        dispatch({
            type: SET_CATEGORY_OPTIONS,
            payload: res.data.data
        });
        return response = {
            status: 'success',
            message:'Categories Fetched Successfully.'
        }
    }).catch(error=>{
        setLoading(dispatch,'fetch-categories',false);
        console.log(error)
        if(!error){
            console.log({
                status:'Network Error',
                message:'Backend Api is not working'
            })
        }
        if(error && error?.response?.status == 401){
                return response={
                    status:'logout',
                    message:'You have been logged out'
                }
        }
    });

    return response;
    
}

const setLoading = (dispatch, apiName, loadingState) => {

    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
}