import {SET_CATEGORY_OPTIONS} from './../Actions/index';

const initialState = {
    categoryOptions:[]
}

export const categoryReducer = (state=initialState,action) => {
    switch(action.type){
        case SET_CATEGORY_OPTIONS:
            return {
                ...state,
                categoryOptions:action.payload
            };
        default: return state;
    }
}