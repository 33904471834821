import axios from "axios";
import { USER_LOGIN, USER_REGISTRATION, ADD_LOADING, REMOVE_LOADING, USER_LOGOUT } from "./index";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL

export const loginUser = (userDetails) => async(dispatch) => {
    axios.defaults.timeout = 10000;
    let response;
    setLoading(dispatch, 'userLogin', true);
    const { email, password } = userDetails;
    const url = `${BASE_API_URL}/auth/login`;

    let data = {
        username: email,
        password: password,
    };
    let config = {
        credentials: "include",
        method: "post",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
        },
        data: data,
        crossDomain: true,
    };

    await axios(config).then((res) => {
        if (res.status == 200) {
            if (res.data.statusCode == '200') {
                response = {
                    status: 'success',
                    message: 'Login Successful',
                    data : res.data
                }
                localStorage.setItem('user', JSON.stringify(res.data));
                dispatch({
                    type: USER_LOGIN,
                    payload: res.data
                })
            }
            setLoading(dispatch, 'userLogin', false);
        }
    }
    ).catch(error => {
        // if (!error.response) {
            // Network error occurred
            // console.error('Network error:', error);
            // response={
            //     status:'internal-server-error',
            //     message:'Server is Closed'
            // }
        //     return;
        //   }
        if(error.status==401){
            response={
                status:'logout',
                message:'You have been logged out'
            }
        return;
        }
        setLoading(dispatch, 'userLogin', false);
        if(error.message.includes('timeout')){
            response = {
                status: 'error',
                message: 'Request Timeout'
            }
        };
        if (error.status == '400' || error.status == '404') {
            response = {
                status: 'error',
                message: error.messages[0],
            }
        }
    });
    
    return response;
}

export const verifyUser = (accessToken) => async(dispatch) => {
    
    const url = `${BASE_API_URL}/auth/isTokenValid`;
    let response;

    const config = {
        method: "post",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization": `Bearer ${accessToken}`
        },
        crossDomain: true,
    }

    await axios(config).then(res=>{
        response = {
            status:'success',
            message:'Token Verified'
        }
    }).catch(error=>{
        response = {
            status:'error',
            message:'Invalid Token'
        }
    })
    return response;
}

export const registerUser = (userDetails) => (dispatch) => {
    setLoading(dispatch, 'userRegister', true);

    const res = userDetails;
    dispatch({
        type: USER_REGISTRATION,
        payload: res
    })
    setTimeout(() => {
        setLoading(dispatch, 'userRegister', false);
    }, 5000)
}

export const logout = () => async(dispatch) => {
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    if(!accessToken){
        return;
    }
    const url = `${BASE_API_URL}/auth/logout`;
    let response;

    const config = {
        method: "post",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization": `Bearer ${accessToken}`
        },
        crossDomain: true,
    }
    await axios(config).then(res=>{
        response = {
            status:'success',
            message:'Logout Successful'
        }
    }).catch(error=>{
        response = {
            status:'error',
            message:'Invalid Token'
        }
    })
    return response;

}

export const setLoadingValue = (api,loadingStatus) => (dispatch) => {
    setLoading(dispatch, api,loadingStatus);
}


const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
}