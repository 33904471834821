import { ADD_LOADING,REMOVE_LOADING } from "./../Actions"

const initialState = {
    loading:[],
}

let loadingArray;
export const loadingState = (state=initialState,action)=>{
    switch(action.type){
        case ADD_LOADING:
            loadingArray = state.loading;
            loadingArray.push(action.payload);
            return {
                ...state,
                loading:loadingArray
            };
        case REMOVE_LOADING:
            loadingArray = state.loading.filter(el=> el!==action.payload);
            return{
                ...state,
                loading:loadingArray
            }
        default: return state
    }
}