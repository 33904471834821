import React, { useState, useRef, useEffect } from 'react'
import Documents from './Documents/Documents';
import Configuration from './Configuration/Configuration';
import { logout } from '../../Redux/Actions/action.users';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from './../firebaseConfig';
import TalkToYourData from './TalkToYourData/TalkToYourData';


function Dashboard({ logout, toast, closeToast }) {
    const [page, setPage] = useState('configuration');
    const userInfo = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):'';
    const menuRef = useRef(null);
    const userAccountOptionsRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userAccountOptionsVisible, setUserAccountOptionsVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        window.addEventListener('resize',onResize);
        return ()=>{
            window.removeEventListener('resize',onResize);
        }
    },[]);

    const onResize = () => {
        if(window.innerWidth < 1024){
            setIsMenuOpen(false);
        }
    }

    const handleLogout = () => {
        logout().then(res=>{
            if(res && res.status=='success'){
                signOut(auth).then(res=>{
                    navigate('/');
                })
            }
            if(res && res.status=='error'){
                signOut(auth).then(res=>{
                    navigate('/')
                })
            }
        })
    }

    return (
        <div className='dashboard-container'>
            <div className={`left-side d-flex flex-column ${!isMenuOpen ? 'menu-close' : 'menu-open'}`} ref={menuRef} >
                <div className='dashboard-logo d-flex align-items-center'>
                    <img src='./images/logo.png' className='my-3 mx-2' alt='logo' />
                    <div className={`ms-auto me-3 burgerMenuIcon menu-icon ${!isMenuOpen ? 'menu-close' : 'menu-open'}`} onClick={() => { setIsMenuOpen(prev=>!prev) }}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>
                <div className={`dashboard-greetings`}>
                    <p className='greeting mb-1'>Welcome Back</p>
                    <p className='username'>{userInfo?.user?.displayName}</p>
                </div>
                <div className='dashboard-links px-2'>
                    <div className='links-container'>
                        <div role="button" className={`px-4 py-2 link ${page == 'configuration' ? 'active' : ''}`} onClick={() => { setPage('configuration');setIsMenuOpen(false) }}>Configuration</div>
                        <div role="button" className={`px-4 py-2 link ${page == 'sync-documents' ? 'active' : ''}`} onClick={() => { setPage('sync-documents');setIsMenuOpen(false) }}>Sync Documents</div>
                        <div role="button" className={`px-4 py-2 link ${page == 'talk-to-your-data' ? 'active' : ''}`} onClick={() => { setPage('talk-to-your-data');setIsMenuOpen(false) }}>Talk to Your Data</div>
                    </div>
                </div>
                <div className='mt-auto mx-3 user-account-dropdown-option' onClick={() => { handleLogout() }}>
                    <i className="fa-solid fa-right-from-bracket"></i><p className='ms-2 mb-0'>Logout</p>
                </div>
            </div>
            <div className='right-side d-flex flex-column'>
                <div className='document-ingestion-container-wrapper mt-0 mt-md-4' id='document-ingestion-container-wrapper'>
                    {page == 'sync-documents' && <Documents toast={toast} closeToast={closeToast} />}
                    {page == 'configuration' && <Configuration toast={toast} closeToast={closeToast} />}
                    {page == 'talk-to-your-data' && <TalkToYourData toast={toast} closeToast={closeToast} />}
                </div>
                <div className='dashboard-footer py-3 mt-auto'>
                    <p className='my-0'>Copyright &copy; 2024 by BLATTNER TECHNOLOGIES. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)