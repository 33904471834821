import { ADD_TOAST, REMOVE_TOAST } from ".";


export const addToast = (newToast)=>(dispatch)=>{
    dispatch({
        type:ADD_TOAST,
        payload: newToast
    })    
}

export const removeToast = (toastId)=>(dispatch)=>{
    dispatch({
        type:REMOVE_TOAST,
        payload: toastId
    })
}